/* Sass Document */
/*Servicios */
/*Tipografias */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
.fondo-opacidad-negro {
  background-image: linear-gradient(rgba(225, 225, 225, 0.87), rgba(225, 225, 225, 0.87));
  border: 2px solid #EABF2D;
  border-radius: 25px;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px; }

#servicios {
  min-height: 100vh;
  color: #FFFFFF; }
  #servicios h2 {
    z-index: 2;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-top: 100px;
    font-weight: 700;
    font-size: 50px;
    text-transform: uppercase; }
  #servicios .row {
    position: relative;
    z-index: 2;
    padding-top: 5%;
    padding-bottom: 10%; }
    #servicios .row div {
      padding-top: 4%; }
    #servicios .row h3 {
      padding-top: 15px;
      font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 22px; }
    #servicios .row a {
      font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
      text-transform: uppercase; }

@media only screen and (min-width: 501px) and (max-width: 767px) {
  #servicios h2 {
    font-size: 45px; } }
@media only screen and (max-width: 500px) {
  #servicios h2 {
    font-size: 40px; } }
