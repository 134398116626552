/* Sass Document */
/*Card */
/*Tipografias */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
.servicio-section .servicio-container .subSer {
  margin-top: 15px;
  margin-botton: 25px; }
.servicio-section .servicio-container div h2 {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  margin-top: 15px;
  color: #000000; }
.servicio-section .servicio-container div button {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase; }
.servicio-section .servicio-container div .btn-outline-dark:hover, .servicio-section .servicio-container div .btn-outline-dark:active, .servicio-section .servicio-container div .btn-outline-dark:focus {
  background-color: #FEB904;
  border-color: #FEB904;
  color: #FFFFFF; }

@media only screen and (min-width: 1200px) {
  .servicio-section .servicio-container div h2 {
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-top: 15px; } }
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .servicio-section .servicio-container div h2 {
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px; } }
