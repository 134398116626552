/* Sass Document */
/*Footer */
/*Tipografias */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
footer {
  padding: 25px 0;
  text-align: center;
  background-color: #FFFFFF; }
  footer span.copyright {
    text-transform: uppercase;
    text-transform: none;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 40px; }
  footer ul.quicklinks {
    margin-bottom: 0;
    text-transform: uppercase;
    text-transform: none;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 40px; }
  footer ul.social-buttons {
    margin-bottom: 0; }
  footer ul.social-buttons li {
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px; }
  footer ul.social-buttons li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-size: 20px;
    line-height: 40px;
    outline: 0;
    color: #fff;
    background-color: #222;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    margin-right: 0px; }
    footer ul.social-buttons li a:hover, footer ul.social-buttons li a:focus, footer ul.social-buttons li a:active {
      background-color: #FEB904; }
