/* Sass Document */
/*Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Noeteric-Bold";
  src: url(../../../../assets/fonts/neoteric/Noeteric-Bold.eot), url(../../../../assets/fonts/neoteric/Noeteric-Bold.otf), url(../../../../assets/fonts/neoteric/Noeteric-Bold.ttf);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: .5s ease;
  background-image: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22));
  z-index: -1;
}

.jarallax {
  height: 100%;
}
