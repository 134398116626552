/* Sass Document */
/*Modal */
/*Tipografias */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
.modal-content {
  border: 2px solid #EABF2D !important;
  background-color: rgba(255, 255, 255, 0.87) !important; }
  .modal-content .modal-header {
    border-bottom: none; }
    .modal-content .modal-header button {
      color: #000000 !important; }
      .modal-content .modal-header button:hover, .modal-content .modal-header button:active, .modal-content .modal-header button:focus {
        color: #FEB904 !important; }
      .modal-content .modal-header button span {
        font-size: 25px; }
  .modal-content .modal-footer {
    border-top: none !important; }
    .modal-content .modal-footer .btn {
      background-color: transparent !important;
      border-color: #000000 !important; }
      .modal-content .modal-footer .btn:hover, .modal-content .modal-footer .btn:active, .modal-content .modal-footer .btn:focus {
        background-color: #FEB904 !important;
        border-color: #FEB904 !important;
        color: #FFFFFF; }
