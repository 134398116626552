/* Sass Document */
/*InfoCard */
/*Tipografias */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
.servicio-section .servicio-container div {
  padding: auto; }
  .servicio-section .servicio-container div h1 {
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #000000;
    font-size: 25px;
    font-weight: 700; }
  .servicio-section .servicio-container div p {
    color: #000000; }
