/* Sass Document */
/*Navbar */
/*Tipografias */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
#Navbar-container {
  border-bottom: 2px solid #EABF2D;
  background-color: rgba(255, 255, 255, 0.87) !important;
  border-radius: 0px 0px 0px 95px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  /* ANIMATED X */
  /* ANIMATED X COLLAPSED */
  /* END ANIMATED X */ }
  #Navbar-container .container-fluid {
    max-width: 1170px;
    padding: 0px; }
  #Navbar-container li a {
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    color: #000;
    letter-spacing: 1px;
    font-size: 14px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s; }
    #Navbar-container li a:hover, #Navbar-container li a:focus, #Navbar-container li a:active {
      color: #fed136 !important; }
  #Navbar-container .navbar-nav .nav-item .nav-link.active {
    color: #FEB904 !important;
    font-size: 15px; }
  #Navbar-container .navbar-nav .nav-item .nav-link {
    color: #000000 !important; }
    #Navbar-container .navbar-nav .nav-item .nav-link:hover, #Navbar-container .navbar-nav .nav-item .nav-link:target {
      color: #FEB904 !important;
      font-size: 15px; }
  #Navbar-container .dropdown-item.active,
  #Navbar-container .dropdown-item:active {
    background-color: transparent !important; }
  #Navbar-container button:focus {
    outline: none !important; }
  #Navbar-container .navbar-toggler {
    padding-right: 25px;
    border: none;
    background: transparent !important; }
    #Navbar-container .navbar-toggler:hover, #Navbar-container .navbar-toggler:active, #Navbar-container .navbar-toggler:focus {
      border: none;
      background: transparent !important; }
    #Navbar-container .navbar-toggler .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      background-color: #000000;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
    #Navbar-container .navbar-toggler .icon-bar + .icon-bar {
      margin-top: 4px; }
  #Navbar-container .navbar-toggler.x .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 10% 10%;
    -ms-transform-origin: 10% 10%;
    transform-origin: 10% 10%; }
  #Navbar-container .navbar-toggler.x .icon-bar:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0); }
  #Navbar-container .navbar-toggler.x .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 10% 90%;
    -ms-transform-origin: 10% 90%;
    transform-origin: 10% 90%; }
  #Navbar-container .navbar-toggler.x.collapsed .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0); }
  #Navbar-container .navbar-toggler.x.collapsed .icon-bar:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100); }
  #Navbar-container .navbar-toggler.x.collapsed .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0); }

.navbar-default {
  position: absolute !important;
  border-bottom: 0px solid #EABF2D !important;
  border-top: 2px solid #EABF2D;
  border-radius: 95px 0px 0px 0px !important;
  z-index: 2;
  width: 100%; }
