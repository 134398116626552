/* Sass Document */
/*Servicios*/
/*Tipografias*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
.successContact {
  display: none;
}

.errorContact {
  display: none;
}

.sending {
  display: none;
}

.fondo-opacidad-negro {
  background-image: linear-gradient(rgba(225, 225, 225, 0.87), rgba(225, 225, 225, 0.87));
  border: 2px solid #EABF2D;
  border-radius: 25px;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
}

#contacto {
  min-height: 100vh;
  color: #FFFFFF;
}

#contacto::-webkit-input-placeholder, #contacto:-moz-placeholder, #contacto::-moz-placeholder, #contacto:-ms-input-placeholder {
  text-transform: uppercase;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 700;
  color: #bbb;
}

#contacto h2 {
  z-index: 2;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  padding-top: 100px;
  font-weight: 700;
  font-size: 50px;
  text-transform: uppercase;
}

#contacto p {
  z-index: 2;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1.5vw;
}

#contacto p a {
  color: #FFFFFF;
  text-decoration: none;
}

#contacto p a:hover, #contacto p a:focus, #contacto p a:active {
  color: #FFFFFF;
  text-decoration: none;
}

#contacto .row {
  position: relative;
  z-index: 2;
  padding-top: 3%;
  padding-bottom: 3%;
}

#contacto button {
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  text-transform: uppercase;
  margin-bottom: 15px;
}

#contacto .mapa {
  height: 80vh;
}

#contacto .section-heading {
  color: #fff;
}

#contacto .form-group {
  margin-bottom: 25px;
}

#contacto .form-group input,
#contacto .form-group textarea {
  padding: 20px;
}

#contacto .form-group input.form-control {
  height: auto;
}

#contacto .form-group textarea.form-control {
  min-height: 249px;
}

#contacto .form-control:focus {
  border-color: #fed136;
  box-shadow: none;
}

#contacto .text-danger {
  color: #e74c3c;
}

@media only screen and (min-width: 1200px) {
  #contacto p {
    z-index: 2;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1.8vw;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #contacto p {
    z-index: 2;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 2.3vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #contacto p {
    z-index: 2;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 3vw;
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  #contacto p {
    z-index: 2;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 3vw;
  }
}

@media only screen and (max-width: 500px) {
  #contacto p {
    z-index: 2;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 4.5vw;
  }
}
