/* Sass Document */
/*Desarrollo Organizacional*/
/*Tipografias*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
#formacion-capacitacion-y-adiestramiento {
  min-height: 100vh;
  background-color: #f7f7f7;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/img/jpg/Capacitacion.jpg");
  background-repeat: no repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

#formacion-capacitacion-y-adiestramiento #CapacitacionContainer {
  padding-top: 100px;
  padding-bottom: 50px;
}
