/* Sass Document */
/*Desarrollo Organizacional*/
/*Tipografias*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
#envia-cv {
  min-height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/img/jpg/EnviaTuCV.jpg");
  background-repeat: no repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

#envia-cv #EnviaCVContainer {
  padding-top: 100px;
  color: #FFFFFF;
}

#envia-cv #EnviaCVContainer h2 {
  z-index: 2;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  padding-bottom: 50px;
  font-weight: 700;
  font-size: 50px;
  text-transform: uppercase;
}

#envia-cv #EnviaCVContainer .sending {
  display: none;
}

#envia-cv #EnviaCVContainer .success {
  display: none;
}

#envia-cv #EnviaCVContainer .error {
  display: none;
}

#envia-cv #EnviaCVContainer input {
  padding: 20px;
}

#envia-cv #EnviaCVContainer textarea {
  min-height: 218px;
}

#envia-cv #EnviaCVContainer .archivo1 {
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
