/* Sass Document */
/*Inicio*/
/*Tipografias*/
#pelotasContainer table {
  border: none !important;
}

#pelotasContainer table thead {
  border: none !important;
}

#pelotasContainer table thead th {
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 50px;
}

#pelotasContainer .roundedSinBordes {
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 50px;
}

#pelotasContainer .backgroundGreen {
  background-color: #00DB12;
  color: #FFFFFF;
  border-left: none !important;
  border-right: none !important;
}

#pelotasContainer .backgroundRed {
  background-color: #F2280C;
  color: #FFFFFF;
  border-left: none !important;
  border-right: none !important;
}

#pelotasContainer .backgroundYellow {
  background-color: #F5DC1A;
  color: #FFFFFF;
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}
