/* Sass Document */
/*Nosotros */
/*Tipografias */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
#quienes-somos {
  background-color: #f7f7f7;
  color: #000000;
  padding-top: 100px;
  padding-bottom: 100px; }
  #quienes-somos h2 {
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-bottom: 25px;
    font-weight: 700;
    font-size: 50px;
    text-transform: uppercase; }
