/* Sass Document */
/*Portada*/
/*Tipografias*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
@font-face {
  font-family: "Noeteric-Bold";
  src: url(../../../../../assets/fonts/neoteric/Noeteric-Bold.eot), url(../../../../../assets/fonts/neoteric/Noeteric-Bold.otf), url(../../../../../assets/fonts/neoteric/Noeteric-Bold.ttf);
}

#inicio {
  height: 100vh;
  padding: 0px;
  display: block;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.56));
  color: #FFFFFF;
}

#inicio #logoPortadaContainer {
  position: absolute;
  top: 100px;
}

#inicio #logoPortadaContainer img {
  position: absolute;
  margin: 0 auto 0 auto;
  z-index: 2;
}

#inicio h1 {
  position: absolute;
  font-family: "Noeteric-Bold";
  z-index: 2;
  top: 35vh;
}

#inicio a {
  border-radius: 150px;
  z-index: 2;
  top: 60vh;
  position: absolute;
}

#inicio a button {
  height: 75px;
  width: 75px;
  font-size: 30px;
  font-weight: 400;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

@media only screen and (max-height: 450px) {
  #inicio #logoPortadaContainer {
    top: 50px;
    max-width: 50%;
  }
  #inicio a {
    top: 50vh;
  }
}
